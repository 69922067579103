import React, { useMemo, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { regions } from "../../data/country";
import { Block, Button, DocumentScan, Input, Loading } from "../../../components/Component";
import { useForm } from "react-hook-form";
import RSelect2 from "../../../components/select/ReactSelect2";
import { apiRequest } from "../../../utils/Api";
import countryList from "react-select-country-list";
import { useEffect } from "react";
import { Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

const ThirdParties = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  const [loading, setLoading] = useState(true);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [load, setLoad] = useState(0);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  if (load < 1) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoad(2);
  }
  const marital_options = [
    { value: "married", label: "Married" },
    { value: "single", label: "Single" },
    { value: "common law", label: "Common Law" },
    { value: "divorced", label: "Divorced" },
    { value: "windowed", label: "Windowed" },
  ];

  useEffect(() => {
    setLoading(false);
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const y_n_options = [
    { value: "y", label: "Yes" },
    { value: "n", label: "No" },
  ];
  const role_options = [
    { value: 1, label: "Power of Attorney" },
    { value: 2, label: "Executor" },
    { value: 3, label: "Trustee" },
    { value: 4, label: "Guardian" },
    { value: 5, label: "Conservator" },
    { value: 6, label: "Proxy" },
    { value: 7, label: "Parent" },
  ];
  const income_options = [
    { value: "0 - $35,000", label: "0 - $35,000" },
    { value: "$35,001 - $70,000", label: "$35,001 - $70,000" },
    { value: "$70,001 - $125,000", label: "$70,001 - $125,000" },
    { value: "$125,001 - $250,000", label: "$125,001 - $250,000" },
    { value: "$250,001+", label: "$250,001+" },
  ];

  const [selectedValue2, setSelectedValue2] = useState(null);

  const [docTitle, setDocTitle] = useState([
    "Please upload the power of attorney appointing you as attorney",
    "Please upload the power of attorney appointing you as attorney",
    "Please upload the certificate appointing you as executor or other document such as the will",
    "Please upload the trust declaration or document appointing you as a trustee",
    "Please upload the power of attorney appointing you as attorney",
    "Please upload the power of attorney appointing you as attorney",
    "Please upload the power of attorney appointing you as attorney",
    "Please upload your child's birth certificate, ID document or other form of documentation",
  ]);

  const [formData, setFormData] = useState({
    request_id: "200bb518-7ff3-4b72-90f5-6297496ee286",
    representing: "",
    role: "",
    preparer_name: "",
    preparer_email: "",
    preparer_phone: "",
    witness_name: "",
    witness_email: "",
    witness_phone: "",
    other_name: "",
    other_email: "",
    other_phone: "",
    image1: "",
  });

  const pronoun_options = [
    { value: "He/Him", label: "He/Him" },
    { value: "She/Her", label: "She/Her" },
    { value: "They/Them", label: "They/Them" },
    { value: "Other", label: "Other" },
  ];

  const onselect = (name, value) => {
    console.log(name);
    console.log(value);
    // console.log(errors);

    if (name === "is_estate" && value === "n") {
      reset();
      // setValue("legal_region", "");
    }
    if (name === "legal_country") {
      setValue("legal_region", "");
    }
    if (name === "residence_country") {
      setValue("residence_region", "");
    }
    if (getValues(name)) {
      delete errors[name];
      // console.log(errors);
    }
  };
  const onInputChange = (e) => {
    // handleDropdown1Change(e.target)
    // handleDropdown2Change(e.target)
    // console.log(e);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log(formData);
  };

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async (e) => {
    setSubmitButtonLoading(true);
    // console.log(getValues());
    const location = await locationAccess();
    setValue("latitude", location.latitude);
    setValue("longitude", location.longitude);
    console.log(getValues())



    if (getValues("is_estate") === "n") {
      // console.log("no");
      // localStorage.setItem("at_position", JSON.stringify({ step: 9 }));
      // props.next();
      try {
        await apiRequest("/ccr/kyc/step/8", "POST", getValues()).then((resp) => {
          // resp = JSON.parse(resp);
          // console.log(resp)
          if (resp.code === 200) {
            props.jump(resp.data.step);
          } else {
            setSubmitButtonLoading(false);
            console.log("error response: ", resp.message);
            toast.error(resp.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // console.log("error response: ", resp.message);
          }
        });
      } catch (err) {
        setSubmitButtonLoading(false);
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log(err);
      }
    } else {
      const data = new FormData();
      data.append("cosigner_name", getValues("cosigner_name"));
      data.append("cosigner_email", getValues("cosigner_email"));
      data.append("cosigner_phone", getValues("cosigner_phone"));
      data.append("estate_document_scan", getValues("document")[0]);
      data.append("preparer_email", getValues("preparer_email"));
      data.append("preparer_name", getValues("preparer_name"));
      data.append("preparer_phone", getValues("preparer_phone"));
      data.append("request_id", getValues("request_id"));
      data.append("role_id", Number(getValues("role_id")));
      data.append("Witness_email", getValues("witness_email"));
      data.append("witness_name", getValues("witness_name"));
      data.append("witness_phone", getValues("witness_phone"));
      data.append("is_estate", getValues("is_estate"));
      data.append("title", getValues("title"));
      data.append("name_first", getValues("name_first"));
      data.append("name_middle", getValues("name_middle"));
      data.append("name_last", getValues("name_last"));
      data.append("suffix", getValues("suffix"));
      data.append("aka", getValues("aka"));
      data.append("name_changed", getValues("name_changed"));
      data.append("prev_name", getValues("prev_name"));
      data.append("pronoun", getValues("pronoun"));
      data.append("dob", getValues("dob"));
      data.append("pob", getValues("pob"));
      data.append("citizenship", getValues("citizenship"));
      data.append("tax_id", getValues("tax_id"));
      data.append("marital", getValues("marital"));
      data.append("income", getValues("income"));
      data.append("employer", getValues("employer"));
      data.append("website", getValues("website"));
      data.append("email", getValues("email"));
      data.append("latitude", location.latitude);
      data.append("longitude", location.longitude);
      data.append("phone", getValues('phone'));
      data.append("address1", getValues("address1"));
      data.append("address2", getValues("address2"));
      data.append("country", getValues("country"));
      data.append("region", getValues("region"));
      data.append("city", getValues("city"));
      data.append("postal", getValues("postal"));
      data.append("occupation", getValues("occupation"));
      // console.log(data)
      try {
        await apiRequest("/ccr/kyc/step/8", "POST", data, "file").then((resp) => {
          resp = JSON.parse(resp);
          if (resp.code === 200) {
            localStorage.setItem("at_position", JSON.stringify({ step: resp.data.step }));
            props.next();
          } else {
            setSubmitButtonLoading(false);
            console.log("error response: ", resp.message);
          }
        });
      } catch (err) {
        setSubmitButtonLoading(false);
        console.log(err);
      }
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // console.log('submit');

    await handleSubmit(onFormSubmit)();

    if (Object.keys(errors).length) {
      toast.error('missing information', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (
    <React.Fragment>
      <Head title="Third Parties" />
      <div className="mb-2"></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className="content-page wide-md m-auto">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>Third Parties</h5>
              <p>Please make sure all the information you have provided is correct and true.</p>
              <form className="content clearfix" onSubmit={handleFormSubmit}>
                <div className="gy-4 row pb-5">
                  <div className="entry">
                    <RSelect2
                      onselect={onselect}
                      setValue={setValue}
                      selectedAct={watch("is_estate")}
                      label="Will you be acting on behalf of or representing someone else? (eg: Power of Attorney, Executor, Trustee, Guardian, or for a child who does not have a goverment issued photo ID)"
                      register={register}
                      requiredMessage="This field is required"
                      id="is_estate"
                      name="is_estate"
                      errors={errors}
                      options={y_n_options}
                      placeholder="Select"
                      className="form-control-lg p-0 border-0 form-control"
                    />
                    {watch("is_estate") === "y" && (
                      <div className="mt-2">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          selectedAct={watch("role_id")}
                          label="If yes, please describe your role:"
                          register={register}
                          requiredMessage="This field is required"
                          id="role_id"
                          name="role_id"
                          errors={errors}
                          options={role_options}
                          placeholder="Select your role"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                        {watch("role_id") && (
                          <div>
                            <DocumentScan
                              value={watch("document")}
                              requiredMessage="This field is required"
                              required
                              errors={errors}
                              capture="environment"
                              title={docTitle[watch("role_id")]}
                              id="2"
                              register={register}
                              name="document"
                              onChange={setValue}
                              view="0 0 512 512"
                              trans="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              pri="M871 5104 c-205 -55 -369 -264 -416 -529 -13 -77 -15 -321 -15 -1951 l0 -1864 -142 0 c-188 -1 -234 -20 -273 -113 -20 -48 -19 -78 5 -173 61 -239 229 -423 423 -464 36 -7 535 -10 1657 -8 l1605 3 55 22 c170 70 300 232 363 451 l22 77 3 1903 2 1902 400 0 c423 0 442 2 494 48 26 23 56 91 56 128 0 55 -39 187 -82 274 -74 149 -201 258 -345 295 -85 22 -3729 21 -3812 -1z m3365 -145 c-78 -85 -131 -186 -169 -319 l-22 -75 -5 -2000 c-6 -2230 1 -2036 -75 -2190 -125 -257 -380 -328 -571 -161 -86 74 -154 207 -181 351 -18 98 -47 145 -105 174 l-44 21 -1252 0 -1252 0 2 1898 c3 1778 4 1901 21 1957 42 144 133 278 223 331 94 55 0 53 1798 53 l1668 1 -36 -41z m475 8 c70 -32 128 -83 175 -154 44 -66 89 -178 99 -245 6 -39 4 -49 -14 -67 -21 -21 -28 -21 -416 -21 l-396 0 6 38 c42 248 157 412 328 468 60 20 153 12 218 -19z m-1641 -4347 c11 -11 20 -32 20 -47 0 -97 79 -282 166 -386 l55 -67 -1423 2 -1423 3 -55 26 c-72 34 -129 85 -176 156 -44 66 -89 178 -99 245 -6 39 -4 49 14 67 l21 21 1440 0 c1427 0 1440 0 1460 -20z"
                              sec="M2900 4780 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 460 -20 427 0 441 1 460 20 11 11 20 29 20 40 0 11 -9 29 -20 40 -19 19 -33 20 -460 20 -427 0 -441 -1 -460 -20z M3220 4460 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 300 -20 267 0 281 1 300 20 11 11 20 29 20 40 0 11 -9 29 -20 40 -19 19 -33 20 -300 20 -267 0 -281 -1 -300 -20z M820 1340 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 300 -20 267 0 281 1 300 20 11 11 20 29 20 40 0 11 -9 29 -20 40 -19 19 -33 20 -300 20 -267 0 -281 -1 -300 -20z M820 1020 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 460 -20 427 0 441 1 460 20 25 25 25 55 0 80 -19 19 -33 20 -460 20 -427 0 -441 -1 -460 -20z"
                              ter="M2255 4079 c-52 -12 -1041 -532 -1079 -567 -48 -45 -56 -78 -56 -229 0 -113 4 -145 19 -179 21 -45 56 -77 107 -96 l34 -12 0 -408 c0 -380 -1 -409 -17 -414 -49 -15 -88 -42 -112 -78 -24 -36 -26 -50 -29 -162 -4 -144 11 -192 74 -240 l37 -29 1087 0 1087 0 37 29 c63 48 78 96 74 240 -3 112 -5 126 -29 162 -26 38 -47 54 -101 74 l-28 10 0 408 0 408 34 12 c51 19 86 51 107 96 15 34 19 66 19 179 0 155 -8 186 -61 232 -47 41 -1010 546 -1073 562 -56 15 -73 15 -131 2z m180 -150 c279 -143 925 -486 943 -502 21 -19 22 -28 22 -145 0 -103 -3 -129 -17 -144 -15 -17 -61 -18 -1061 -18 -959 0 -1047 1 -1064 17 -16 14 -18 33 -18 144 l0 128 33 24 c41 31 949 508 996 524 48 16 94 8 166 -28z m-795 -1339 l0 -410 -120 0 -120 0 0 410 0 410 120 0 120 0 0 -410z m440 0 l0 -410 -160 0 -160 0 0 410 0 410 160 0 160 0 0 -410z m360 0 l0 -410 -120 0 -120 0 0 410 0 410 120 0 120 0 0 -410z m440 0 l0 -410 -160 0 -160 0 0 410 0 410 160 0 160 0 0 -410z m360 0 l0 -410 -120 0 -120 0 0 410 0 410 120 0 120 0 0 -410z m140 -550 c17 -17 20 -33 20 -122 0 -80 -3 -105 -17 -120 -15 -17 -61 -18 -1061 -18 -959 0 -1047 1 -1064 17 -15 14 -18 32 -18 120 0 90 3 106 20 123 20 20 33 20 1060 20 1027 0 1040 0 1060 -20z M2268 3731 c-71 -23 -128 -100 -128 -172 1 -50 33 -112 76 -145 33 -25 46 -29 104 -29 58 0 71 4 104 29 43 33 75 95 76 145 0 50 -37 119 -79 147 -41 26 -111 38 -153 25z m92 -131 c24 -24 25 -48 4 -78 -31 -44 -104 -16 -104 40 0 24 35 58 60 58 11 0 29 -9 40 -20z"
                            />

                            <div className="mt-3">
                              <h6>Who prepared or applied for this document (eg. name of lawyer)?</h6>
                              <div
                                style={{
                                  paddingLeft: "35px",
                                  paddingBottom: "10px",
                                }}
                                className="custom-control-group "
                              >
                                <div className="custom-control custom-checkbox">
                                  <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="check1"
                                        checked={watch("check1")}
                                        {...register("check1")}
                                        onSelect={() => {
                                          setValue("check1", !getValues("check1"));
                                        }}
                                      />

                                      <label className="custom-control-label" htmlFor="check1">
                                        <div>Does not apply</div>
                                      </label>
                                      {errors.check1 && <p className="invalid">{errors.check1.message}</p>}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div>
                                {!watch("check1") && (
                                  <div>
                                    <Input
                                      errors={errors}
                                      required
                                      requiredMessage={{
                                        required: "This field is required",
                                        pattern: {
                                          value: /^.{1,80}$/,
                                          message: "Max 80 characters",
                                        },
                                      }}
                                      register={register}
                                      type="text"
                                      label="Full Name"
                                      id="preparer_name"
                                      name="preparer_name"
                                      className="form-control-lg form-control"
                                    ></Input>

                                    <Input
                                      errors={errors}
                                      required
                                      requiredMessage={{
                                        required: "This field is required",
                                        pattern: {
                                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                          message: "Please enter valid email address",
                                        },
                                      }}
                                      register={register}
                                      type="text"
                                      label="Email"
                                      id="preparer_email"
                                      name="preparer_email"
                                      className="form-control-lg form-control"
                                    ></Input>
                                    <div className="mt-2">
                                      <Input
                                        errors={errors}
                                        required
                                        requiredMessage={{
                                          required: "This field is required",
                                          pattern: {
                                            value: /^\+?1?[2-9][0-8][0-9]\d{3}\d{4}$/,
                                            message: "Please input valid phone number",
                                          },
                                        }}
                                        register={register}
                                        type="tel"
                                        label="Phone"
                                        id="preparer_phone"
                                        name="preparer_phone"
                                        className="form-control-lg form-control"
                                      ></Input>
                                    </div>
                                  </div>
                                )}

                                <div className="mt-3">
                                  <h6>Who witnessed or issued this document (if more than one, name just one)?</h6>
                                  <div
                                    style={{
                                      paddingLeft: "35px",
                                      paddingBottom: "10px",
                                    }}
                                    className="custom-control-group "
                                  >
                                    <div className="custom-control custom-checkbox">
                                      <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="check2"
                                            checked={watch("check2")}
                                            {...register("check2")}
                                            onSelect={() => {
                                              setValue("check2", !getValues("check2"));
                                            }}
                                          />

                                          <label className="custom-control-label" htmlFor="check2">
                                            <div>Does not apply</div>
                                          </label>
                                          {errors.check2 && <p className="invalid">{errors.check1.message}</p>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!watch("check2") && (
                                    <div>
                                      <div className="mt-2">
                                        <Input
                                          errors={errors}
                                          required
                                          requiredMessage={{
                                            required: "This field is required",
                                            pattern: {
                                              value: /^.{1,80}$/,
                                              message: "Max 80 characters",
                                            },
                                          }}
                                          register={register}
                                          type="text"
                                          label="Full Name"
                                          id="witness_name"
                                          name="witness_name"
                                          className="form-control-lg form-control"
                                        ></Input>
                                      </div>
                                      <div className="mt-2">
                                        <Input
                                          errors={errors}
                                          required
                                          requiredMessage={{
                                            required: "This field is required",
                                            pattern: {
                                              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                              message: "Please enter valid email address",
                                            },
                                          }}
                                          register={register}
                                          type="email"
                                          label="Email"
                                          id="witness_email"
                                          name="witness_email"
                                          className="form-control-lg form-control"
                                        ></Input>
                                      </div>

                                      <Input
                                        errors={errors}
                                        required
                                        requiredMessage={{
                                          required: "This field is required",
                                          pattern: {
                                            value: /^\+?1?[2-9][0-8][0-9]\d{3}\d{4}$/,
                                            message: "Please input valid phone number",
                                          },
                                        }}
                                        register={register}
                                        type="tel"
                                        label="Phone"
                                        id="witness_phone"
                                        name="witness_phone"
                                        className="form-control-lg form-control"
                                      ></Input>
                                    </div>
                                  )}
                                </div>

                                <div className="mt-3">
                                  <h6>Anyone else who is required to make decisions with you.</h6>
                                  <div
                                    style={{
                                      paddingLeft: "35px",
                                      paddingBottom: "10px",
                                    }}
                                    className="custom-control-group "
                                  >
                                    <div className="custom-control custom-checkbox">
                                      <div style={{ paddingBottom: "10px" }} className="custom-control-group ">
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="check3"
                                            checked={watch("check3")}
                                            {...register("check3")}
                                            onSelect={() => {
                                              setValue("check3", !getValues("check3"));
                                            }}
                                          />

                                          <label className="custom-control-label" htmlFor="check3">
                                            <div>Does not apply</div>
                                          </label>
                                          {errors.check3 && <p className="invalid">{errors.check3.message}</p>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!watch("check3") && (
                                    <div>
                                      <div
                                        onChange={(e) => {
                                          onInputChange(e);
                                        }}
                                        className="mt-2"
                                      >
                                        <Input
                                          errors={errors}
                                          required
                                          requiredMessage={{
                                            required: "This field is required",
                                            pattern: {
                                              value: /^.{1,80}$/,
                                              message: "Max 80 characters",
                                            },
                                          }}
                                          register={register}
                                          type="text"
                                          label="Full Name"
                                          id="cosigner_name"
                                          name="cosigner_name"
                                          className="form-control-lg form-control"
                                        ></Input>
                                      </div>
                                      <div className="mt-2">
                                        <Input
                                          errors={errors}
                                          required
                                          requiredMessage={{
                                            required: "This field is required",
                                            pattern: {
                                              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                              message: "Please enter valid email address",
                                            },
                                          }}
                                          register={register}
                                          type="email"
                                          label="Email"
                                          id="cosigner_email"
                                          name="cosigner_email"
                                          className="form-control-lg form-control"
                                        ></Input>
                                      </div>
                                      <div className="mt-2">
                                        <Input
                                          errors={errors}
                                          required
                                          requiredMessage={{
                                            required: "This field is required",
                                            pattern: {
                                              value: /^\+?1?[2-9][0-8][0-9]\d{3}\d{4}$/,
                                              message: "Please input valid phone number",
                                            },
                                          }}
                                          register={register}
                                          type="tel"
                                          label="Phone"
                                          id="cosigner_phone"
                                          name="cosigner_phone"
                                          className="form-control-lg form-control"
                                        ></Input>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 card-bordered text-soft p-2">
                              <h6>About Third-Party: Please tell us about the Third-Party.</h6>
                              <div
                                onChange={(e) => {
                                  onInputChange(e);
                                }}
                                className="mt-2"
                              >
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="Title"
                                  id="title"
                                  name="title"
                                  className="form-control-lg form-control"
                                ></Input>
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  requiredMessage="This field is required"
                                  required
                                  label="First Name"
                                  id="name_first"
                                  name="name_first"
                                  className="form-control-lg form-control"
                                ></Input>
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="Middle Name(s)"
                                  id="name_middle"
                                  name="name_middle"
                                  className="form-control-lg form-control"
                                ></Input>
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  requiredMessage="This field is required"
                                  required
                                  label="Last Name"
                                  id="name_last"
                                  name="name_last"
                                  className="form-control-lg form-control"
                                ></Input>
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="Suffix"
                                  id="suffix"
                                  name="suffix"
                                  className="form-control-lg form-control"
                                ></Input>
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="Also Known As"
                                  id="aka"
                                  name="aka"
                                  className="form-control-lg form-control"
                                ></Input>
                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedAct={watch("third_party_name_changed")}
                                  label="Did the third-party change legal names?"
                                  register={register}
                                  requiredMessage="This field is required"
                                  id="name_changed"
                                  name="name_changed"
                                  errors={errors}
                                  options={y_n_options}
                                  placeholder="Select"
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                                {watch("name_changed") === "y" && (
                                  <Input
                                    errors={errors}
                                    register={register}
                                    type="text"
                                    requiredMessage="This field is required"
                                    required
                                    label="Previous Name"
                                    id="prev_name"
                                    name="prev_name"
                                    className="form-control-lg form-control"
                                  ></Input>
                                )}
                                <RSelect2
                                  onNameChange={watch("pronoun")}
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedTitle={watch("title")}
                                  label="Pronoun"
                                  // requiredMessage="This field is required"
                                  errors={errors}
                                  register={register}
                                  options={pronoun_options}
                                  name="pronoun"
                                  color="#f5f5f5"
                                  id="pronoun"
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="Email"
                                  id="email"
                                  name="email"
                                  className="form-control-lg form-control"
                                  requiredMessage={{
                                    // required: "This field is required",
                                    pattern: {
                                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                      message: "Please enter valid email address",
                                    },
                                  }}
                                ></Input>
                                <Input
                                  errors={errors}
                                  // required
                                  requiredMessage={{
                                    // required: "This field is required",
                                    pattern: {
                                      value: /^\+?1?[2-9][0-8][0-9]\d{3}\d{4}$/,
                                      message: "Please input valid phone number",
                                    },
                                  }}
                                  register={register}
                                  type="tel"
                                  label="Phone"
                                  id="phone"
                                  name="phone"
                                  className="form-control-lg form-control"
                                ></Input>
                                <Input
                                  type="text"
                                  label="Address Line 1"
                                  id="address1"
                                  className="form-control-lg form-control"
                                  name="address1"
                                  // required
                                  requiredMessage={{
                                    // required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                />
                                <Input
                                  type="text"
                                  label="Address Line 2"
                                  id="address2"
                                  className="form-control-lg form-control"
                                  name="address2"
                                  errors={errors}
                                  requiredMessage={{
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  register={register}
                                />
                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch("location_residence")}
                                  register={register}
                                  // requiredMessage="This field is required"
                                  errors={errors}
                                  options={countryOptions}
                                  placeholder="Select country"
                                  label="Country"
                                  id="country"
                                  name="country"
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                                {watch("country") !== "CA" && watch("country") !== "US" && (
                                  <Input
                                    type="text"
                                    className="form-control-lg form-control"
                                    // required
                                    requiredMessage={{
                                      // required: "This field is required",
                                      pattern: {
                                        value: /^.{1,20}$/,
                                        message: "Max 20 characters",
                                      },
                                    }}
                                    errors={errors}
                                    register={register}
                                    label="Province/State"
                                    id="region"
                                    name="region"
                                  />
                                )}
                                {watch("country") === "US" && (
                                  <RSelect2
                                    onselect={onselect}
                                    setValue={setValue}
                                    selectedCountry={watch("region")}
                                    register={register}
                                    // requiredMessage="This field is required"
                                    errors={errors}
                                    options={regions[watch("country")]}
                                    placeholder="Select Region"
                                    className="form-control-lg p-0 border-0 form-control"
                                    label="Province/State"
                                    id="region"
                                    name="region"
                                  />
                                )}
                                {watch("country") === "CA" && (
                                  <RSelect2
                                    onselect={onselect}
                                    setValue={setValue}
                                    selectedCountry={watch("region")}
                                    register={register}
                                    // requiredMessage="This field is required"
                                    errors={errors}
                                    options={regions[watch("country")]}
                                    placeholder="Select Region"
                                    className="form-control-lg p-0 border-0 form-control"
                                    label="Province/State"
                                    id="region"
                                    name="region"
                                  />
                                )}
                                <Input
                                  type="text"
                                  label="City"
                                  id="city"
                                  className="form-control-lg form-control"
                                  name="city"
                                  // required
                                  requiredMessage={{
                                    // required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                />
                                <Input
                                  type="text"
                                  label="Postal/Zip Code"
                                  id="postal"
                                  className="form-control-lg form-control"
                                  name="postal"
                                  required
                                  requiredMessage={{
                                    // required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  errors={errors}
                                  register={register}
                                />
                                <Input
                                  onNameChange={watch("dob")}
                                  type="date"
                                  label="Date of Birth"
                                  id="dob"
                                  name="dob"
                                  className="form-control-lg form-control date-picker-alt"
                                  register={register}
                                  errors={errors}
                                ></Input>
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="Place of Birth"
                                  id="pob "
                                  name="pob"
                                  className="form-control-lg form-control"
                                ></Input>
                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch("third_party_citizenship")}
                                  label="Citizenship"
                                  errors={errors}
                                  register={register}
                                  options={countryOptions}
                                  name="citizenship"
                                  color="#f5f5f5"
                                  id="citizenship"
                                  placeholder="Select country"
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="SSC/SIN/TAX ID"
                                  id="tax_id "
                                  name="tax_id"
                                  className="form-control-lg form-control"
                                ></Input>
                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch("third_party_marital")}
                                  label="Marital Status"
                                  errors={errors}
                                  register={register}
                                  options={marital_options}
                                  name="marital"
                                  color="#f5f5f5"
                                  id="marital"
                                  // placeholder="Select country"
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                                <Input
                                  // max={formatDate()}
                                  // onNameChange={watch("name_previous")}
                                  // required
                                  requiredMessage={{
                                    // required: "This field is required",
                                    pattern: {
                                      value: /^.{1,20}$/,
                                      message: "Max 20 characters",
                                    },
                                  }}
                                  type="text"
                                  label="Occupation"
                                  id="occupation"
                                  name="occupation"
                                  className="form-control-lg form-control date-picker-alt"
                                  register={register}
                                  errors={errors}
                                ></Input>

                                <RSelect2
                                  onselect={onselect}
                                  setValue={setValue}
                                  selectedCountry={watch("third_party_income")}
                                  label="Annual Income"
                                  errors={errors}
                                  register={register}
                                  options={income_options}
                                  name="income"
                                  color="#f5f5f5"
                                  id="income"
                                  // placeholder="Select country"
                                  className="form-control-lg p-0 border-0 form-control"
                                />
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="Employer"
                                  id="employer"
                                  name="employer"
                                  className="form-control-lg form-control"
                                ></Input>
                                <Input
                                  errors={errors}
                                  register={register}
                                  type="text"
                                  label="Employer Website"
                                  id="website"
                                  name="website"
                                  className="form-control-lg form-control"
                                ></Input>

                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="mt-3 nk-kycfm-note">
                      <em className=" icon ni ni-info-fill"></em>
                      <p>You cannot edit your details once submitted.</p>
                    </div>
                  </div>
                </div>
                {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
                <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                  <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                    <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                      {" "}
                      <Button color="primary" size="" className="" outline="">
                        {submitButtonLoading && <Spinner size="sm" />} Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Block>
          </div>
          <ToastContainer />
        </Content>
      )}
    </React.Fragment>
  );
};

export default ThirdParties;
