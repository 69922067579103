import React, { useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, BlockAthenty, Icon, Loading } from "../../../../../components/Component";
import { Card } from "reactstrap";
import ClientActivities from "./ClientActivities/ClientActivities";
import ClientVerification from "./ClientVerification/ClientVerification";
import ClientNote from "./ClientNote/ClientNote";
import { ClientDetails } from "../../../../data/clientDetails";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../../../utils/Api";
import { useLocation } from "react-router-dom";

const ClientDetail = () => {
  const [loading, setLoading] = useState();
  const [selectedTab, setSelectedTab] = useState("Verifications");
  const path = useLocation();
  const splitedPath = path.pathname.split("/");
  const client_id = splitedPath[splitedPath.length - 1];
  const [data, setData] = useState();
  const location = useLocation();
  // const request_id = queryParameters.get("request_id")
  console.log(client_id);
  useEffect(() => {
    getClientDetail();
  }, []);

  const getClientDetail = async () => {
    try {
      setLoading(true);
      await apiRequest(`/customer/a/client/${client_id}`, "GET").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp);
          setData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      // setLoading(false)
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      {(loading) && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Organization"}
            nav="Menu / Client"
            title={`${data && data.name_first} ${data && data.name_last}`}
            description={`Client ID: ${data && data.external_id}`}
            back
            buttons={[]}
          />
          <Block>
            <Card className="card-bordered">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                {/* <li
                  onClick={() => setSelectedTab("Profile")}
                  className={`nav-item ${selectedTab === `Profile` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Profile" ? "active" : ""}`}>
                    <Icon name="user-circle"></Icon>
                    <span>Profile</span>
                  </div>
                </li> */}
                <li
                  onClick={() => setSelectedTab("Verifications")}
                  className={`nav-item ${selectedTab === `Verifications` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Verifications" ? "active" : ""}`}>
                    <Icon name="check-circle-cut"></Icon>
                    <span>Verifications</span>
                  </div>
                </li>
                {/* <li
                  onClick={() => setSelectedTab("Biometrics")}
                  className={`nav-item ${selectedTab === `Biometrics` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Biometrics" ? "active" : ""}`}>
                    <Icon name="user-check"></Icon>
                    <span>Biometrics</span>
                  </div>
                </li>
                <li
                  onClick={() => setSelectedTab("Documents")}
                  className={`nav-item ${selectedTab === `Documents` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Documents" ? "active" : ""}`}>
                    <Icon name="cc-secure"></Icon>
                    <span>Documents</span>
                  </div>
                </li> */}
                {/* <li
                  onClick={() => setSelectedTab("Activities")}
                  className={`nav-item ${selectedTab === `Activities` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Activities" ? "active" : ""}`}>
                    <Icon name="activity"></Icon>
                    <span>Activity</span>
                  </div>
                </li> */}
                <li
                  onClick={() => setSelectedTab("Notes")}
                  className={`nav-item ${selectedTab === `Notes` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Notes" ? "active" : ""}`}>
                    <Icon name="pen"></Icon>
                    <span>Notes</span>
                  </div>
                </li>
              </ul>

              <div className="card-inner card-inner-lg">
                {/* {selectedTab === "Profile" && <ClientProfile data={data} />} */}
                {selectedTab === "Verifications" && <ClientVerification client_id={client_id} />}
                {/* {selectedTab === "Biometrics" && <ClientBiometrics data={data.biometrics} />}
                {selectedTab === "Documents" && <ClientDocuments data={data.documents} />} */}
                {selectedTab === "Activities" && <ClientActivities data={ClientDetails.activities} />}
                {selectedTab === "Notes" && <ClientNote data={ClientDetails.notes} />}
              </div>
            </Card>
          </Block>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default ClientDetail;
