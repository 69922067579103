import React from "react";
import Content from "../../../../../layout/content/Content";
import GalleryImage from "../../../../../components/partials/gallery/GalleryImage";

const role_options_estate = [
  { value: 1, label: "Power of Attorney" },
  { value: 2, label: "Executor" },
  { value: 3, label: "Trustee" },
  { value: 4, label: "Guardian" },
  { value: 5, label: "Conservator" },
  { value: 6, label: "Proxy" },
  { value: 7, label: "Parent" },
];

const getEstateRoleFromId = (id) => {
  for (let i = 0; i < role_options_estate.length; i++) {
    if (role_options_estate[i].value === id) {
      return role_options_estate[i].label;
    }
  }
};

const ThirdPartiesTab = ({ data }) => {
  console.log(data);
  return (
    <React.Fragment>
      {data && (
        <Content>
          <div className="nk-block-head">
            <h5 className="title">Third Parties</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Client's Role
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.role_id ? getEstateRoleFromId(data.estate.role_id) : "Not collected"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block-head">
            <h5 className="title">Documentation</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "18%",
                      }}
                      className="profile-ud-label"
                    >
                      Prepared or applied by
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "32%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.preparer_name ? data.estate.preparer_name : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "18%",
                      }}
                      className="profile-ud-label"
                    >
                      Witnessed or applied by
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "32%",
                      }}
                      id="name_first"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.witness_name ? data.estate.witness_name : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "18%",
                      }}
                      className="profile-ud-label"
                    >
                      Email
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "32%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.preparer_email ? data.estate.preparer_email : "Not collected"}
                    </span>

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "18%",
                      }}
                      className="profile-ud-label"
                    >
                      Email
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "32%",
                      }}
                      id="name-last"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.witness_email ? data.estate.witness_email : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "18%",
                      }}
                      className="profile-ud-label"
                    >
                      Phone
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "32%",
                      }}
                      id="suffix"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.preparer_phone ? data.estate.preparer_phone : "Not collected"}
                    </span>

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "18%",
                      }}
                      className="profile-ud-label"
                    >
                      Phone
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "32%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.witness_phone ? data.estate.witness_phone : "Not collected"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block-head">
            <h5 className="title">Documents</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Document
                    </span>

                    <span
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      {data.estate && data.estate.document_s3 ? (
                        <GalleryImage img={data.estate.document_s3} height="250px" />
                      ) : (
                        <span
                          style={{
                            textAlign: "left",
                            fontSize: "14px",

                            width: "20%",
                            color: "#526484",
                            fontWeight: "500",
                          }}
                          id="city"
                        >
                          Not collected
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block-head">
            <h5 className="title">Other Decision Makers</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Name
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.cosigner_name ? data.estate.cosigner_name : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Email
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.cosigner_email ? data.estate.cosigner_email : "Not collected"}
                    </span>

                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Phone
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.cosigner_phone ? data.estate.cosigner_phone : "Not collected"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block-head">
            <h5 className="title">About the Third Party</h5>
          </div>

          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">



                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Title
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_title ? data.estate.third_title : "Not collected"}
                    </span>




                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Date of Birth
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_first"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_dob ? data.estate.third_dob : "Not collected"}
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />

                  <div className="profile-ud wider">

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      First Name
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_first"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_name_first ? data.estate.third_name_first : "Not collected"}
                    </span>

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Citizenship
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name-last"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_citizenship ? data.estate.third_citizenship : "Not collected"}
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />

                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Middle Name
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_name_middle ? data.estate.third_name_middle : "Not collected"}
                    </span>

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      SSC/SIN/Tax ID
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_tax_id ? data.estate.third_tax_id : "Not collected"}
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />

                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Last Name
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_name_last ? data.estate.third_name_last : "Not collected"}
                    </span>

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Marital Status
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_marital ? data.estate.third_marital : "Not collected"}
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />

                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Suffix
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_suffix ? data.estate.third_suffix : "Not collected"}
                    </span>

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Annual Income
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_income ? data.estate.third_income : "Not collected"}
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />

                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Previous Name
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_prev_name ? data.estate.third_prev_name : "Not collected"}
                    </span>

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Employer
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_employer ? data.estate.third_employer : "Not collected"}
                    </span>
                  </div>


                  <hr style={{ margin: "0" }} />

                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Pronouns
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_pronoun ? data.estate.third_pronoun : "Not collected"}
                    </span>

                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Employer Website
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_website ? data.estate.third_website : "Not collected"}
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />

                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Email
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {data.estate && data.estate.third_email ? data.estate.third_email : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Address
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {data.estate.address1 ?
                        `${data.estate.address1}, ${data.estate.city || 'No City'}, ${data.estate.region || 'No Region'}, ${data.estate.postal || 'No Postal'}, ${data.estate.country || 'No Country'}` : "Not collected"}
                    </span>


                  </div>


                </div>
              </div>
            </div>
          </div>
        </Content>
      )}
    </React.Fragment>
  );
};

export default ThirdPartiesTab;
