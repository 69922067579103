import React, { useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, BlockAthenty, Button, Icon, Input, Loading, Textarea } from "../../../../../components/Component";
import { Card, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { ClientDetails } from "../../../../data/clientDetails";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../../../utils/Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import MatterTab from "../../tabs/mattertab/MatterTab";
import ProfileTab from "../../tabs/profileTab/ProfileTab";
import BiometricsTab from "../../tabs/biometricsTab/BiometricsTab";
import DocumentsTab from "../../tabs/documentsTab/DocumentsTab";
import ActivitiesTab from "../../tabs/activitiesTab/ActivitiesTab";
import NoteTab from "../../tabs/noteTab/NoteTab";
import ComplianceTab from "../../tabs/complianceTab/ComplianceTab";
import HistoryTab from "../../tabs/historyTab/HistoryTab";

const VerifyDetail = () => {
  const [loading, setLoading] = useState();
  const [selectedTab, setSelectedTab] = useState("Matter");
  const [data, setData] = useState();
  const [historyData, setHistoryData] = useState();
  const [modal, setModal] = useState(false);
  const [cancelModal, setcancelModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const location = useLocation();
  const [clickedButtonId, setClickedButtonId] = useState(null);
  const [dLoading, setDLoading] = useState();
  const [distanceError, setDistanceError] = useState()
  const [documentsError, setDocumentsError] = useState()
  const steps = [
    "initiated",
    "welcome",
    "document_1",
    "document_2",
    "person",
    "location",
    "contact",
    "biometric_face",
    "biometric_voice",
    "estate",
    "business",
  ];

  const params = useParams();
  const request_id = params.id;

  const navigate = useNavigate();


  const onSubmitShareForm = async () => {
    await apiRequest(`/customer/a/solution/smart-kyc/${request_id}/share`, "post", getValues2()).then((resp) => {
      if (resp.code === 200) {
        toggleShareModal();
        navigate(`/customer/solutions/verify/${request_id}`);
        toast.success("Share request sent.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log("shared");
      } else {
        toast.error("Something went wrong. Please contact support.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
    setSelectedTab("Matter");
  };

  useEffect(() => {
    if (location.hash.length > 0) setSelectedTab(location.hash.replace("#", ""));
    getRequestDetails();
    getHistoryDetail();
  }, []);

  const handleTabSelect = (tab) => {
    setModal(false)
    window.location.hash = tab
    setSelectedTab(tab)
    // console.log(selectedTab)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleShareSubmit,
    formState: { errors: errors2 },
    reset: reset2,
    getValues: getValues2,
  } = useForm();

  const toggleModal = () => {
    // console.log("toggle");
    setModal(!modal);
    reset();
  };

  const toggleCancelModal = () => {
    setcancelModal(!cancelModal);
  };

  const toggleShareModal = () => {
    // console.log("toggle");
    setShareModal(!shareModal);
    reset2();
  };

  const cancelRequest = async () => {
    await apiRequest(`/customer/a/solution/smart-verify/${params.id}/cancel`, "PUT").then((resp) => {
      if (resp.code === 200) {
        navigate("/customer/solutions/verify", { state: "requestDelet" });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const getRequestDetails = async (decisionrResp = null) => {
    setLoading(true);
    try {
      await apiRequest(`/customer/a/solution/smart-verify/${params.id}`, "GET").then((resp) => {
        console.log(resp);
        if (resp.code === 200) {
          // console.log(resp.data);
          setData(resp.data);
          if (resp.data.location && resp.data.location.documents) {
            for (let doc of resp.data.location.documents) {
              console.log('something')
              if (doc.mobile_distance_exceeding['15km'].length) setDistanceError(true)
              console.log(distanceError)
            }
          }
          console.log(resp.data.documents)
          if (resp.data.documents.length) {
            for (let doc of resp.data.documents) {
              console.log(doc.score)
              if (doc.score < 90) setDocumentsError(true)

            }
          }
          console.log(documentsError)
          if (decisionrResp) {
            toast.success(decisionrResp, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setLoading(false);
          }
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  };

  const getHistoryDetail = async () => {
    // console.log('getHistory')
    try {
      setLoading(true);
      await apiRequest(`/customer/a/solution/smart-kyc/${params.id}/history`, "GET").then(async (resp) => {
        // console.log(resp);
        if (resp.code === 200) {
          // console.log(resp.data);
          setHistoryData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      // setLoading(false)
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const resendSmartVerify = async () => {
    setLoading(true);
    try {
      await apiRequest(`/customer/a/solution/smart-kyc/${params.id}/resend`, "POST").then((resp) => {
        // console.log(resp);
        if (resp.code === 200) {
          // console.log(resp.data);
          setLoading(false);
          toast.success(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  }

  const downloadPdf = (data) => {
    // console.log(data);
    const link = document.createElement('a');
    const isBase64 = !data.includes('https://');

    if (isBase64) {
      // If the data is a base64 string, set it as the href
      link.href = `data:application/pdf;base64,${data}`;
      link.download = `ASV_${params.id}.pdf`; // Set the download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // If the data is a direct URL, fetch the file and create a blob URL
      setLoading(true);
      fetch(data)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = `ASV_${params.id}.pdf`; // Set the download filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
          URL.revokeObjectURL(url); // Clean up the object URL
        })
        .catch(error => {
          console.error('Failed to download PDF:', error);
          setLoading(false);
        });
    }
  };

  const getPdf = async () => {
    setLoading(true);
    try {
      const response = await apiRequest(`/customer/a/pdf/${params.id}`, "get");
      if (response.data) {
        downloadPdf(response.data);
      } else {
        toast.error("Failed to load PDF data.");
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
      toast.error("Error fetching PDF.");
    }
    setLoading(false);
  }

  const setDecision = async () => {
    setDLoading(true);
    if (Object.keys(errors).length > 0) return;
    try {
      await apiRequest(`/customer/a/solution/smart-verify/${params.id}/decision`, "PUT", {
        result: clickedButtonId,
        remark: getValues("remarks"),
      }).then(async (resp) => {
        // console.log(resp);
        if (resp.code === 200) {
          toggleModal();
          setDLoading(false);
          setClickedButtonId();
          // console.log(resp.message);
          getRequestDetails(resp.message);
        } else {
          // console.log("toast");

          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      setDLoading(false);
      // console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleClickedButton = (value) => {
    if (Object.keys(errors).length > 0) return;
    // console.log(value);
    setClickedButtonId(value);
  };


  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Smart IDV"}
            back
            nav="Solutions / Smart IDV"
            title={data && data.name}
            description={`Matter: ${data && data.matter_id} (${data && data.matter_reason})`}
            buttons={[
              // data &&
              // data.status === "approved" &&
              {
                text: "Share Report",
                icon: "share",
                link: "",
                onclick: toggleShareModal,
                tooltip:
                  data && data.status !== "complete" && data.active
                    ? "Unable to share report until request reviewed"
                    : data && !data.active
                      ? "The request has been canceled"
                      : "Share Report",
                id: "sharePdf",
                form: "my-form",
                color: "primary",
                hidden: data && data.status === "canceled" ? true : false,
                disabled: data && data.status !== "complete",
              },
              {
                text: "Download Report",
                icon: "download",
                link: "",
                // onclick: { toggleModal },
                tooltip:
                  data && data.status !== "complete" && data.active
                    ? "Unable to download report until request reviewed"
                    : data && !data.active
                      ? "The request has been canceled"
                      : "Download Report",
                id: "downloadPdf",
                form: "my-form",
                // linkTo: `/download-pdf/${params.id}`,
                onclick: getPdf,
                color: "primary",
                newTab: true,
                hidden: data && data.status === "canceled" ? true : false,
                disabled: data && data.status !== "complete",
                // disabled: "disabled",
              },
            ].filter(Boolean)}
            extra={[
              {
                type: "badge",
                title: "Status",
                value:
                  data && data.active
                    ? ((data.status.charAt(0).toUpperCase() + data.status.slice(1)).replace("_", " ") + (data.result && ` - ${data.result}`))
                    : data && !data.active
                      ? "Canceled"
                      : "",
                className: "",
                outline: "warning",
                textColor: "success",
                color: data && (!data.active ? 'warning' :
                  (data.status === 'complete' && data.result === 'Passed') ? 'success'
                    : (data.status === 'complete' && data.result === 'Failed') ? 'danger'
                      : 'primary')
                // size: "sm",
              },

              {
                type: "text",
                title: "Return Date",
                value:
                  data && data.submit_date
                    ? moment.utc(data.submit_date).local().format("YYYY-MM-DD hh:mm")
                    : "Incomplete",
              },
              {
                type: "text",
                title: "Review Date",
                value:
                  data && data.review_date
                    ? moment.utc(data.review_date).local().format("YYYY-MM-DD hh:mm")
                    : "Not reviewed",
              },
            ]}
            extraButtons={
              // extraButtons
              [
                {
                  text: "Resend",
                  icon: "redo",
                  link: "",
                  onclick: resendSmartVerify,
                  tooltip:
                    data && data.status !== "initiated"
                      ? "Can not resend after verification started"
                      : "Resend Smart IDV Request",
                  id: "resend-request",
                  form: "my-form",
                  linkTo: ``,
                  color: "gray",
                  // hidden: data && data.status !== "initiated" ? true : false,
                  disabled: data && data.status !== "initiated" ? true : false,
                  outline: false,
                },
                {
                  text: "Review",
                  icon: "eye-alt",
                  link: "",
                  onclick: toggleModal,
                  tooltip:
                    data && steps.includes(data.status) && data.active
                      ? "Wait for form submission to review request"
                      : data && data.status === "complete"
                        ? "Already reviewed"
                        : data && !data.active
                          ? "The request has been canceled"
                          : "Review",
                  id: "review-request",
                  form: "my-form",
                  linkTo: ``,
                  color: "warning",
                  hidden: data && data.status === "canceled" ? true : false,
                  disabled: (data && steps.includes(data.status)) || (data && data.status === "complete"),
                  outline: false,
                },
                {
                  text: "Cancel",
                  icon: "cross",
                  link: "",
                  onclick: toggleCancelModal,
                  tooltip:
                    data && !steps.includes(data.status) && data.active
                      ? "Cannot be cancelled once completed by client."
                      : data && !data.active
                        ? "The request has been canceled"
                        : "Cancel Smart IDV Request",
                  id: "cancel-request",
                  form: "my-form",
                  linkTo: ``,
                  color: "gray",
                  hidden: data && data.status === "canceled" ? true : false,
                  disabled: data && (!steps.includes(data.status) || !data.active),
                  outline: false,
                },

              ]
            }
          />
          <Block>
            <Card className="card-bordered">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li
                  onClick={() => handleTabSelect("Matter")}
                  className={`nav-item pe-4 ${selectedTab === `Matter` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Matter" ? "active" : ""}`}>
                    <Icon name="list"></Icon>
                    <span>Matter</span>
                  </div>
                </li>
                <li
                  onClick={() => handleTabSelect("Profile")}
                  className={`nav-item pe-4 ${selectedTab === `Profile` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Profile" ? "active" : ""}`}>
                    <Icon name="user-circle"></Icon>
                    <span>About</span>
                  </div>
                </li>
                {/* <li
                  onClick={() => handleTabSelect("Third-Parties")}
                  className={`nav-item pe-4 } ${selectedTab === `Third-Parties` ? "active current-page" : ""} `}
                >
                  <div role="button" className={` nav-link ${selectedTab === "Third-Parties" ? "active" : ""}`}>
                    <Icon name="users" color="danger"></Icon>
                    <span>Third-Parties</span>
                  </div>
                </li> */}
                {/* <li
                  onClick={() => handleTabSelect("Business")}
                  className={`nav-item pe-4 } ${selectedTab === `Business` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Business" ? "active" : ""}`}>
                    <Icon name="briefcase"></Icon>
                    <span>Business</span>
                  </div>
                </li> */}
                <li
                  onClick={() => handleTabSelect("Documents")}
                  className={`nav-item pe-4 ${selectedTab === `Documents` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Documents" ? "active" : ""}`}>
                    <Icon name="cc-secure"></Icon>
                    <span>Documents</span>
                  </div>
                </li>
                {/* <li
                onClick={()=>handleTabSelect('Verifications')}
                className={`nav-item pe-4 ${
                  selectedTab === `Verifications` ? "active current-page" : ""} `}
              >
                <div
                  role="button"
                  className={`nav-link ${selectedTab === 'Verifications' ? "active" : ""}`}
                  
                >
                  <Icon name="check-circle-cut"></Icon>
                  <span>Verifications</span>
                </div>
              </li> */}
                <li
                  onClick={() => handleTabSelect("Biometrics")}
                  className={`nav-item pe-4 ${selectedTab === `Biometrics` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Biometrics" ? "active" : ""}`}>
                    <Icon name="eye-alt"></Icon>
                    <span>Biometrics</span>
                  </div>
                </li>
                <li
                  onClick={() => handleTabSelect("Compliance")}
                  className={`nav-item pe-4 ${selectedTab === `Compliance` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Compliance" ? "active" : ""}`}>
                    <Icon name="list-check"></Icon>
                    <span>Compliance</span>
                  </div>
                </li>
                <li
                  onClick={() => {
                    handleTabSelect("History");
                    getHistoryDetail();
                  }}
                  className={`nav-item pe-4 ${selectedTab === `History` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "History" ? "active" : ""}`}>
                    <Icon name="history"></Icon>
                    <span>History</span>
                  </div>
                </li>
              </ul>
              {!data ? <Loading /> :
                <div className="card-inner ">
                  {selectedTab === "Matter" && <MatterTab data={data} />}
                  {selectedTab === "Profile" && <ProfileTab more={data} type={'verify'} />}
                  {/* {selectedTab === "Verifications" && <VerifyVerification data={data} />} */}
                  {selectedTab === "Biometrics" && <BiometricsTab data={data} />}
                  {selectedTab === "Documents" && <DocumentsTab data={data} />}
                  {selectedTab === "Activities" && <ActivitiesTab data={ClientDetails.activities} />}
                  {selectedTab === "Notes" && <NoteTab data={ClientDetails.notes} />}
                  {selectedTab === "Compliance" && <ComplianceTab data={data} />}
                  {selectedTab === "History" && <HistoryTab data={historyData} />}

                </div>}
            </Card>
          </Block>
          <ToastContainer />
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader
              toggle={toggleModal}
              close={
                <button className="close" disabled={dLoading} onClick={toggleModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              <div>Name: {data && data.name}</div>
              Matter: {data && data.matter_id} / {data && data.matter_reason}
            </ModalHeader>
            <ModalBody>
              {dLoading ? (
                <Loading />
              ) : (
                <>
                  <div>Please check off all areas to confirm reviewed sections have been completed</div>
                  <form id="verify-form" className="is-alter form-validate" onSubmit={handleSubmit(setDecision)}>
                    <Row className="mb-4 mt-4">
                      <Row >

                        <Col>
                          <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist">
                                  <div className="profile-ud wider ms-2">
                                    <span style={{
                                      textAlign: "left",
                                      fontSize: "14px",
                                      margin: "0px 20px 0px 0px",
                                      width: "25%",
                                    }} className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="form-control custom-control-input"
                                        id="matter"
                                        checked={watch("matter")}
                                        {...register("matter", {
                                          required: "This field is required",
                                        })}
                                        onSelect={() => {
                                          setValue("matter", !getValues("matter"));
                                        }}
                                      />
                                      <label className="custom-control-label me-2" htmlFor="matter">
                                        {/* <span onClick={(e) => { e.preventDefault(); handleTabSelect('Documents') }} className="pe-2">Documents</span> */}
                                        <p style={{ display: 'inline-flex', alignItems: 'center' }} onClick={(e) => { e.preventDefault(); handleTabSelect('Matter') }} >Matter</p>
                                        {/* <Badge onClick={(e) => e.preventDefault()} pill color="danger">Confidence: low</Badge> */}
                                      </label>
                                      {errors.matter && <span className="invalid">{errors.matter.message}</span>}
                                    </span>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: "14px",
                                        margin: "0px 20px 0px 0px",
                                        // width: "25%",
                                      }}
                                      id="flags"

                                      onClick={(e) => { e.preventDefault(); handleTabSelect('Matter') }}
                                    >
                                      No Flag
                                    </label>
                                  </div>

                                  <hr style={{ margin: "0" }} />
                                  <div className="profile-ud wider ms-2">
                                    <label style={{
                                      textAlign: "left",
                                      fontSize: "14px",
                                      margin: "0px 20px 0px 0px",
                                      width: "25%",
                                    }} className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="form-control custom-control-input"
                                        id="about"
                                        checked={watch("about")}
                                        {...register("about", {
                                          required: "This field is required",
                                        })}
                                        onSelect={() => {
                                          setValue("about", !getValues("about"));
                                        }}
                                      />
                                      <label className="custom-control-label me-2" htmlFor="about">
                                        {/* <span onClick={(e) => { e.preventDefault(); handleTabSelect('Documents') }} className="pe-2">Documents</span> */}
                                        <p style={{ display: 'inline-flex', alignItems: 'center' }} onClick={(e) => { e.preventDefault(); handleTabSelect('Profile') }} >About</p>
                                        {/* <Badge onClick={(e) => e.preventDefault()} pill color="danger">Confidence: low</Badge> */}
                                      </label>
                                      {errors.about && <span className="invalid">{errors.about.message}</span>}
                                    </label>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: "14px",
                                        // margin: "0px 20px 0px 0px",
                                        // width: "25%",
                                      }}
                                      id="flags"
                                      onClick={(e) => { e.preventDefault(); handleTabSelect('Profile') }}
                                    >
                                      {distanceError ?

                                        <label className="text-danger">Attention Required. Please review and remark.</label>

                                        : (
                                          "No Flag"
                                        )}
                                    </label>
                                  </div>



                                  <hr style={{ margin: "0" }} />
                                  <div className="profile-ud wider ms-2">
                                    <span style={{
                                      textAlign: "left",
                                      fontSize: "14px",
                                      margin: "0px 20px 0px 0px",
                                      width: "25%",
                                    }} className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="form-control custom-control-input"
                                        id="documents"
                                        checked={watch("documents")}
                                        {...register("documents", {
                                          required: "This field is required",
                                        })}
                                        onSelect={() => {
                                          setValue("documents", !getValues("documents"));
                                        }}
                                      />
                                      <label className="custom-control-label me-2" htmlFor="documents">
                                        {/* <span onClick={(e) => { e.preventDefault(); handleTabSelect('Documents') }} className="pe-2">Documents</span> */}
                                        <p style={{ display: 'inline-flex', alignItems: 'center' }} onClick={(e) => { e.preventDefault(); handleTabSelect('Documents') }} >Documents</p>
                                        {/* <Badge onClick={(e) => e.preventDefault()} pill color="danger">Confidence: low</Badge> */}
                                      </label>
                                      {errors.documents && <span className="invalid">{errors.documents.message}</span>}
                                    </span>
                                    <span
                                      style={{
                                        textAlign: "left",
                                        fontSize: "14px",
                                        // margin: "0px 20px 0px 0px",
                                        // width: "25%",
                                      }}
                                      id="flags"
                                      onClick={(e) => { e.preventDefault(); handleTabSelect('Documents') }}
                                    >
                                      {data && documentsError ? (
                                        <label className="text-danger">Attention Required. Please review and remark.</label>
                                      ) : (
                                        <span>No Flag</span>
                                      )}
                                    </span>
                                  </div>

                                  <hr style={{ margin: "0" }} />
                                  <div className="profile-ud wider ms-2">
                                    <span style={{
                                      textAlign: "left",
                                      fontSize: "14px",
                                      margin: "0px 20px 0px 0px",
                                      width: "25%",
                                    }} className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="form-control custom-control-input"
                                        id="biometrics"
                                        checked={watch("biometrics")}
                                        {...register("biometrics", {
                                          required: "This field is required",
                                        })}
                                        onSelect={() => {
                                          setValue("biometrics", !getValues("biometrics"));
                                        }}
                                      />
                                      <label className="custom-control-label me-2" htmlFor="biometrics">
                                        {/* <span onClick={(e) => { e.preventDefault(); handleTabSelect('Documents') }} className="pe-2">Documents</span> */}
                                        <p style={{ display: 'inline-flex', alignItems: 'center' }} onClick={(e) => { e.preventDefault(); handleTabSelect('Biometrics') }} >Biometrics</p>
                                        {/* <Badge onClick={(e) => e.preventDefault()} pill color="danger">Confidence: low</Badge> */}
                                      </label>
                                      {errors.biometrics && <span className="invalid">{errors.biometrics.message}</span>}
                                    </span>
                                    <span
                                      style={{
                                        textAlign: "left",
                                        fontSize: "14px",
                                        // margin: "0px 20px 0px 0px",
                                        // width: "25%",
                                      }}
                                      id="flags"
                                      onClick={(e) => { e.preventDefault(); handleTabSelect('Biometrics') }}
                                    >
                                      {data && data.biometrics && !data.biometrics.status ? (
                                        <label className="text-danger">Attention Required. Please review and remark.</label>
                                      ) : (
                                        <span>No Flag</span>
                                      )}
                                    </span>
                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                      </Row >
                    </Row>
                    <div className="mt-4 ">
                      Please enter any remarks you have regarding this matter and review outcome:
                    </div>
                    <div className="form-control-wrap">
                      <textarea
                        id="remarks"
                        name="remarks"
                        type="text"
                        className="  form-control form-control-lg "
                        {...register("remarks", {
                          required: (data && data.biometrics && !data.biometrics.status) || (data && documentsError) || distanceError ? "This field is required" : false
                        })}
                      ></textarea>
                      {errors.remarks && <span className="invalid">{errors.remarks.message}</span>}
                    </div>
                    <div className="mt-2">
                      Please note, once this review has been submitted, you cannot modify the review again
                    </div>
                    <div className="mt-2">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="form-control custom-control-input"
                          id="agree"
                          checked={watch("agree")}
                          {...register("agree", {
                            required: "This field is required",
                          })}
                          onSelect={() => {
                            setValue("agree", !getValues("agree"));
                          }}
                        />
                        <label className="custom-control-label" htmlFor="agree">
                          I Agree
                        </label>
                        {errors.agree && <span className="invalid">{errors.agree.message}</span>}
                        {/* {errors.agree && <p className="invalid">{errors.agree.message}</p>} */}
                      </div>
                    </div>
                    <div className="center mt-2">
                      <Button
                        disabled={dLoading}
                        onClick={toggleModal}
                        className="center btn border me-2 m-1 closer"
                        style={{ width: "90px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={dLoading}
                        onClick={() => handleClickedButton("rejected")}
                        id="rejected"
                        color="danger"
                        form="verify-form"
                        className="btn btn-primary me-2 m-1 closer"
                      >
                        Fail
                      </Button>
                      <Button
                        disabled={dLoading}
                        onClick={() => handleClickedButton("approved")}
                        id="approved"
                        color="success"
                        form="verify-form"
                        className="btn btn-primary me-2 m-1 closer"
                      >
                        Pass
                      </Button>
                    </div>
                  </form >
                </>
              )}
            </ModalBody >
          </Modal >

          <Modal isOpen={shareModal} toggle={toggleShareModal}>
            <ModalHeader
              toggle={toggleShareModal}
              close={
                <button className="close" onClick={toggleShareModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Share Smart IDV Result PDF
            </ModalHeader>
            <ModalBody>
              <form id="verify-form" className="is-alter form-validate" onSubmit={handleShareSubmit(onSubmitShareForm)}>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <div className="row">
                      <div className="col">
                        <label htmlFor="name" className="form-label">
                          First / Last Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control"
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="name" className="form-label">
                          Email Address <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control"
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="name" className="form-label">
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="mobile"
                          name="mobile"
                          type="text"
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control"
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="name" className="form-label">
                          Company or Business Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="company"
                          name="company"
                          type="text"
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-control-wrap">
                        <Textarea
                          id="reason"
                          name="reason"
                          type="text"
                          label="Reason "
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control form-control-lg"
                        // defaultValue={data && data.matter_reason}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="center mt-2">
                  <Button
                    onClick={toggleShareModal}
                    className="center btn border me-2 m-1 closer"
                    style={{ width: "90px" }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" form="verify-form" className="btn btn-primary me-2 m-1 closer">
                    Share
                  </Button>
                </div>
              </form>
            </ModalBody>
          </Modal>
          <Modal isOpen={cancelModal} toggle={toggleCancelModal}>
            <ModalHeader
              toggle={toggleShareModal}
              close={
                <button className="close" onClick={toggleShareModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Cancel Smart IDV Result
            </ModalHeader>
            <ModalBody>
              <div>
                <p>
                  Are you certain you wish to cancel this verification request? Kindly note that upon submission of
                  cancellation, the request becomes irreversible. You will be unable to view or amend the request, as it
                  will be permanently removed from your verification list.
                </p>
              </div>
              <div className="center mt-2">
                <Button
                  onClick={toggleCancelModal}
                  className="center btn border me-2 m-1 closer"
                  style={{ width: "90px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={cancelRequest}
                  type="submit"
                  form="verify-form"
                  className="btn btn-primary me-2 m-1 closer"
                >
                  Cancel request
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Content >
      )}
    </React.Fragment >
  );
};

export default VerifyDetail;
