import React from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable'


const RSelect2 = ({ ...props }) => {
  // console.log(props.options)
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 38,
      minHeight: 35,
    }),
  };

  const handleBottomArrive = () => {
    console.log("Bottom reached!");
  };

  return (
    <div>
      {props.label && (
        <div className="form-label-group">
          <label className="form-label" htmlFor="default-01">
            {props.label}
            {props.requiredMessage && <span className="text-danger">*</span>}
          </label>
        </div>
      )}
      <div className="form-control-select mb-2">
        {props.createable ? <CreatableSelect
          captureMenuScroll={true}
          onBottomArrive={handleBottomArrive}
          {...props.register(props.name, { required: props.requiredMessage })}
          className={`react-select-container  ${props.className ? props.className : ""}`}
          classNamePrefix="react-select"
          defaultValue={props.options[props.defaultValue]}
          style={{ height: "38px", minHeight: "35px" }}
          onChange={(e, name) => {
            if (Array.isArray(e)) {
              const data = [];
              for (let i = 0; i < e.length; i++) {
                data.push(e[i].value);
              }
              props.setValue(props.name, data);
              props.onselect(name.name, data);
            } else {
              props.setValue(props.name, e.value);
              props.onselect(name.name, e.value);
            }
          }}

          onselect={props.onselect}
          {...props}
          onMenuScrollToBottom={true}
        /> :
          <Select
            {...props.register(props.name, { required: props.requiredMessage })}
            className={`react-select-container  ${props.className ? props.className : ""}`}
            classNamePrefix="react-select"
            defaultValue={props.options[props.defaultValue]}
            style={{ height: "38px", minHeight: "35px" }}
            onChange={(e, name) => {
              if (Array.isArray(e)) {
                const data = [];
                for (let i = 0; i < e.length; i++) {
                  data.push(e[i].value);
                }
                props.setValue(props.name, data);
                props.onselect(name.name, data);
              } else {
                props.setValue(props.name, e.value);
                props.onselect(name.name, e.value);
              }
            }}
            onselect={props.onselect}
            isSearchable={props.isSearchable || false}
            // onMenuScrollToBottom={true}
            {...props}
          />}
        {props.errors && props.errors[props.name] && <p className="invalid">{props.errors[props.name].message}</p>}
      </div>
    </div>
  );
};

export default RSelect2;
