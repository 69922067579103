import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import { useForm } from "react-hook-form";
import { Block, Button, DocumentScan } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import Head from "../../../layout/head/Head";
import { Col, Input, Row } from "reactstrap";
import Blink from 'react-blink-text';
const Ocr = () => {
  const [text, setText] = useState();
  const [image, setImage] = useState();
  const [counter, setCounter] = useState();
  const [multiData, setMultiData] = useState()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const datatest = {
    direct: [
      { Type: { Text: 'FIRST_NAME' }, ValueDetection: { Confidence: 98.14700317382812, Text: 'RAYMOND' } },
      { Type: { Text: 'LAST_NAME' }, ValueDetection: { Confidence: 98.76085662841797, Text: 'LO' } },
      // ... other objects
    ],
    uploaded: [
      { Type: { Text: 'FIRST_NAME' }, ValueDetection: { Confidence: 98.14700317382812, Text: 'RAYMOND' } },
      { Type: { Text: 'LAST_NAME' }, ValueDetection: { Confidence: 98.76085662841797, Text: 'LO' } },
      // ... other objects
    ]
  };

  const onFormSubmit = async () => {
    // console.log(getValues())
    // var startTime = performance.now();
    var data = new FormData();
    data.append("image1", getValues("document1")[0], getValues("document1")[0].name);
    // console.log(data)
    await apiRequest(`/ocr/ocr`, "POST", data, "file").then((resp) => {
      // console.log(JSON.parse(resp));
      console.log(resp)
      const parsedData = JSON.parse(resp)
      console.log(parsedData.data)
      setText(parsedData.data);
      // if (JSON.parse(resp).data.direct) setMultiData(JSON.parse(resp).data)
      // for (data in JSON.parse(resp).data) console.log(data)
      // setImage(JSON.parse(resp).data.image)
      // var endTime = performance.now();
      // setCounter((endTime - startTime) / 1000);
    });
  };

  const onFormSubmit2 = async () => {
    var startTime = performance.now();
    console.log(getValues());
    console.log("2");
    // console.log(data)
    await apiRequest(`/ocr/ocr`, "POST", getValues()).then((resp) => {
      console.log(resp);

      setText(resp.data);
      var endTime = performance.now();
      setCounter((endTime - startTime) / 1000);
    });
  };

  return (
    <React.Fragment>
      <Head title="Invoice Details"></Head>
      {
        <Content>
          <Blink color='red' style={{ color: 'danger' }} text='Geeks Premier League 2023' ></Blink>
          {counter && <div>{counter}</div>}
          {/* <form className="is-alter" onSubmit={handleSubmit(onFormSubmit2)}>
            <div className="form-group">
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  tabIndex={1}
                  {...register("name", { required: "This field is required" })}
                  placeholder="Enter your email address"
                  className="form-control-lg form-control"
                />
                {errors.name && <span className="invalid">{errors.name.message}</span>}
          
                <Button>Submita</Button>
              </div>
            </div>
          </form> */}
          <Block>
            <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <DocumentScan
                    setValue={setValue}
                    height={400}
                    value={watch("document1")}
                    requiredMessage="This field is required"
                    required
                    errors={errors}
                    capture="environment"
                    title="Image"
                    id="1"
                    register={register}
                    name="document1"
                  // onChange={setValue}
                  />
                  <Button>Submit</Button>
                </div>
              </div>
            </form>
          </Block>
          <Block>
            {/* {text &&
              text.map((item) => {
                return (
                  <div>
                    {item.Type.Text} : {item.ValueDetection.Text}
                  </div>
                );
              })} */}
            {text &&

              <ul>
                {Object.entries(text).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key.replace(/_/g, ' ')}:</strong> {value || 'N/A'}
                  </li>
                ))}
              </ul>
            })}


            {multiData &&

              <Row>
                {Object.keys(multiData).map((parentKey) => {
                  return (
                    <Col>
                      <ul>
                        <strong>{parentKey}</strong>
                        {multiData[parentKey].map((child) => {
                          console.log(child)
                          return (
                            <div>{child.Type.Text} : {child.ValueDetection.Text}</div>
                          )
                        })}
                      </ul>
                    </Col>
                  )

                })}
              </Row>
            }

          </Block>

          {image && <img
            // height={80}
            src={image}
            alt="Red dot"
          />}
        </Content>
      }
    </React.Fragment>
  );
};

export default Ocr;
