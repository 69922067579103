import React, { useEffect, useRef } from "react";

const CameraNew = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        console.log('camera')
        // Access the webcam
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true, // Use "video: { facingMode: 'environment' }" for back camera on mobile
                });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error("Error accessing the camera:", err);
            }
        };

        startCamera();

        return () => {
            // Clean up when the component unmounts
            if (videoRef.current && videoRef.current.srcObject) {
                const tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, []);

    return (
        <div>
            <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{ width: "100%", maxWidth: "500px", height: "auto" }}
            />
        </div>
    );
};

export default CameraNew;
