import React, { useEffect, useRef, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, BlockAthenty, Icon } from "../../../../../components/Component";
import { Card } from "reactstrap";
import CustomerProfilePersonal from "./personal/CustomerProfilePersonal";
import CustomerProfileActivity from "./activity/CustomerProfileActivity";
import CustomerProfileNotification from "./notifications/CustomerProfileNotification";
import CustomerProfileSetting from "./settings/CustomerProfileSettings";
import { useLocation } from "react-router";
import LoginHistory from "./loginHistory/LoginHistory";

const CustomerProfile = () => {
  const location = useLocation();
  console.log(location);
  // const receivedData = (location && location.state?.data) || {};
  // console.log(receivedData);
  const [selectedTab, setSelectedTab] = useState("personal");
  const submitRef = useRef();

  const asignRef = () => {
    return submitRef.current.click();
  };
  useEffect(() => {
    if (location.hash.length > 0) setSelectedTab(location.hash.replace("#", ""));
  }, [location.hash]);

  const handleTabSelect = (tab) => {
    window.location.hash = tab
    setSelectedTab(tab)
    console.log(selectedTab)
  }


  return (
    <React.Fragment>
      <Content>
        <BlockAthenty
          head={"Profile"}
          nav="Admin / Access"
          title={"Your Profile"}
          description={"View and update your information."}
          buttons={[
            {
              text: "Save",
              icon: "save",
              link: "",
              onclick: asignRef,
              tooltip: "Save Changes",
              id: "save-button",
              type: "submit",
              form: "my-form",
            },
          ]}
        />
        <Block>
          <Card className="card-bordered">
            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
              <li
                onClick={() => handleTabSelect("personal")}
                className={`nav-item ${selectedTab === `personal` ? "active current-page" : ""} `}
              >
                <div role="button" className={`nav-link ${selectedTab === "personal" ? "active" : ""}`}>
                  <Icon name="user-alt"></Icon>
                  <span>Personal</span>
                </div>
              </li>
              <li
                onClick={() => handleTabSelect("notification")}
                className={`nav-item ${selectedTab === `notification` ? "active current-page" : ""} `}
              >
                <div role="button" className={`nav-link ${selectedTab === "notification" ? "active" : ""}`}>
                  <Icon name="bell"></Icon>
                  <span>Notifications</span>
                </div>
              </li>
              {/* <li
                onClick={() => setSelectedTab("#activity")}
                className={`nav-item ${selectedTab === `activity` ? "active current-page" : ""} `}
              >
                <div role="button" className={`nav-link ${selectedTab === "activity" ? "active" : ""}`}>
                  <Icon name="activity-alt"></Icon>
                  <span>Activity</span>
                </div>
              </li> */}
              <li
                onClick={() => handleTabSelect("security")}
                className={`nav-item ${selectedTab === `security` ? "active current-page" : ""} `}
              >
                <div role="button" className={`nav-link ${selectedTab === "security" ? "active" : ""}`}>
                  <Icon name="shield"></Icon>
                  <span>Security Settings</span>
                </div>
              </li>
              <li
                onClick={() => handleTabSelect("login_history")}
                className={`nav-item ${selectedTab === `login_history` ? "active current-page" : ""} `}
              >
                <div role="button" className={`nav-link ${selectedTab === "login_history" ? "active" : ""}`}>
                  <Icon name="history"></Icon>
                  <span>Login History</span>
                </div>
              </li>
            </ul>

            <div className="card-inner card-inner-lg">
              {selectedTab === "personal" && <CustomerProfilePersonal submitRef={submitRef} />}
              {selectedTab === "activity" && <CustomerProfileActivity submitRef={submitRef} />}
              {selectedTab === "notification" && <CustomerProfileNotification submitRef={submitRef} />}
              {selectedTab === "security" && <CustomerProfileSetting submitRef={submitRef} />}
              {selectedTab === "login_history" && <LoginHistory submitRef={submitRef} />}
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CustomerProfile;
