import React, { useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Button, Icon } from "../../../../../components/Component";
import {
  Badge,
  Col,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import { LuEqual, LuEqualNot } from "react-icons/lu";

const BiometricsTab = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    setModal(!modal);
  };

  console.log(data.biometrics)

  const toggleTooltip = () => {
    setOpen(!tooltipOpen);
  };



  return (
    <React.Fragment>
      <Content>
        {(!data.biometrics || !data.biometrics.facerek.length) && (
          <div className="nk-block-head">
            <div className="card card-bordered card-stretch">
              <h5 className="title" style={{ textAlign: "center", margin: "10px" }}>
                No Biometrics Collected
              </h5>
            </div>
          </div>
        )}
        {(data.biometrics && data.biometrics.facerek.length > 0) && (
          <>
            <div className="nk-block-head">
              <h5 className="title">Liveness</h5>
              <span style={{ textAlign: "right", position: "absolute", right: "0px", top: "-2px" }}>
                <Badge
                  id="remark-"
                  className="p"
                  color={data.biometrics.faceRekScore >= 90
                    ? "success" : data.biometrics.faceRekScore >= 50
                      ? 'warning' : "danger"}><span>Confidence: {data.biometrics.faceRekScore >= 90
                        ? "High" : data.biometrics.faceRekScore >= 50 ? 'Moderate' : "Low"}</span>
                  <Icon name={data.biometrics.faceRekScore >= 90
                    ? "check" : data.biometrics.faceRekScore >= 50
                      ? 'alert' : "cross"}
                  >
                  </Icon>
                </Badge>
                {/* <Button
                  color={data.biometrics.flags.length ? "danger" : "success"}
                  className="btn-xs"
                  // onClick={toggle}
                  id="remark-"
                >
                  <span>{!data.biometrics.status ? "Failed" : "Passed"}</span>{" "}
                  <Icon name={data.biometrics.flags.length ? "cross" : "check"}></Icon>
                </Button> */}
                {/* <Tooltip trigger="hover" placement="top" isOpen={tooltipOpen} target="remark-" toggle={toggleTooltip}>
                  View Biometrics Results
                </Tooltip> */}
              </span>
              {/* <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader
                  toggle={toggle}
                  close={
                    <button className="close" onClick={toggle}>
                      <Icon name="cross" />
                    </button>
                  }
                >
                  Biometrics Results
                </ModalHeader>
                <ModalBody>
                  {data.biometrics.facerek.length > 0 &&
                    data.biometrics.facerek.map((face, index) => {
                      console.log('face:', face);
                      return (
                        <div key={"face-" + index} className="card card-bordered card-stretch">
                          <div className="card-inner-group">
                            <div className="card-inner p-0">
                              <div className="nk-tb-list nk-tb-ulist">
                                <div className="profile-ud wider">
                                  <span
                                    style={{
                                      textAlign: "center",
                                      alignSelf: "center",
                                      margin: "10px",
                                      width: "40%",
                                    }}
                                  >
                                    {<img width="100%" src={data.biometrics.face_url} alt="biometrics face" />}
                                  </span>

                                  <span
                                    style={{
                                      textAlign: "center",
                                      alignSelf: "center",
                                      fontSize: "14px",
                                      margin: "10px",
                                      width: "20%",
                                    }}
                                  >
                                    <p>
                                      {face.status ? <LuEqual size={40} /> : <LuEqualNot size={40} />}
                                    </p>

                                    <p>
                                      Confidence:{" "}
                                      {face.confidence
                                        && face.confidence.toFixed(2)}
                                    </p>
                                  </span>

                                  <span
                                    style={{
                                      textAlign: "center",
                                      alignSelf: "center",
                                      margin: "10px",
                                      width: "40%",
                                    }}
                                  >
                                    {<img width="100%" src={face.file1_url} alt="biometrics face" />}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </ModalBody>
              </Modal> */}
            </div>
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner p-0">
                  <div className="nk-tb-list nk-tb-ulist">
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "25%",
                        }}
                        className="profile-ud-label"
                      >
                        Facial Recognition
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          // width: "50%",
                        }}
                        id="facial-recognition"
                        className="profile-ud-value"
                      >
                        {!data.biometrics.status ? "Failed" : "Pass"}
                      </span>
                    </div>

                    <hr style={{ margin: "0" }} />
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "25%",
                        }}
                        className="profile-ud-label"
                      >
                        Flags
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          // width: "25%",
                        }}
                        id="flags"
                        className={`${data.biometrics && data.biometrics.flags.length !== 0 ? "text-danger" : ""
                          } rofile-ud-value`}
                      >
                        {data.biometrics && data.biometrics.flags.length
                          ? data.biometrics.flags.join(",")
                          : "No flag"}
                      </span>

                    </div>
                    <hr style={{ margin: "0" }} />


                    {data.biometrics.facerek.length > 0 &&
                      data.biometrics.facerek.map((face, index) => {
                        return (
                          <div className="profile-ud wider">
                            <div key={"face-" + index} className="card card-stretch">

                              <h5 className="title ms-3">{face.type}</h5>
                              <div className="nk-tb-list nk-tb-ulist">
                                <div className="profile-ud wider">
                                  <span
                                    style={{
                                      textAlign: "center",
                                      alignSelf: "center",
                                      margin: "10px",
                                      width: "40%",
                                    }}
                                  >
                                    {<img width="60%" src={data.biometrics.face_url} alt="biometrics face" />}
                                  </span>

                                  <span
                                    style={{
                                      textAlign: "center",
                                      alignSelf: "center",
                                      fontSize: "14px",
                                      margin: "10px",
                                      width: "20%",
                                    }}
                                  >
                                    <p>
                                      {typeof face.confidence !== 'string' ? face.status ? <LuEqual size={40} /> : <LuEqualNot size={40} /> : ''}
                                    </p>

                                    <p>

                                      {face.confidence
                                        && typeof face.confidence === 'string' ? face.confidence : "Confidence: " + face.confidence.toFixed(2)}
                                    </p>
                                  </span>

                                  <span
                                    style={{
                                      textAlign: "center",
                                      alignSelf: "center",
                                      margin: "10px",
                                      width: "40%",
                                    }}
                                  >
                                    {<img width="80%" src={face.file1_url} alt="biometrics face" />}
                                  </span>
                                </div>
                              </div>
                            </div>

                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default BiometricsTab;
