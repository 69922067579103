import { Steps, Step } from "react-step-builder";
import { Input, InputRadio, PreviewCard } from "../../../../../../../../components/Component";
import { Button, Card, CardBody, CardFooter, Container } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import RSelect2 from "../../../../../../../../components/select/ReactSelect2";
import { apiRequest } from "../../../../../../../../utils/Api";
import { toast, ToastContainer } from "react-toastify";



const KycWizardUserNew = (props) => {
    console.log(props)
    const { watch, unregister, handleSubmit, clearErrors, register, errors, setValue, getValues, submit, setError } = props.props
    const [isNewMatter, setIsNewMatter] = useState()
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        city: "",
    });

    const onselect = (name, value) => {
        if (name === "matter") {
            setValue("matter", isNaN(value) ? value : props.matterList.filter(obj => obj.value === value)[0].label);
            setValue("request_reason", isNaN(value) ? value : props.matterList.filter(obj => obj.value === value)[0].reason);
            const isExisting = props.matterList.some(option => option.label === getValues('matter'));
            if (!isExisting) {
                setIsNewMatter(true)
                setValue('request_reason', null)
            }
            else setIsNewMatter(false)
        }
        if (getValues(name)) {
            delete errors[name];
        }
    };
    return (
        <div className="form-control-wrap">
            <h4>Client</h4>
            <p>
                Please input Client information
            </p>

            <Container>
                <Row>
                    <Row>
                        <Col xl="4" sm="6" xs="12" className="mb-2">
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <Input
                                        errors={errors}
                                        required
                                        requiredMessage={{
                                            required: "This field is required",
                                            pattern: {
                                                value: /^.{1,40}$/,
                                                message: "Max 40 characters",
                                            },
                                        }}
                                        register={register}
                                        type="text"
                                        label="Client First Name"
                                        id="client_name_first"
                                        name="client_name_first"
                                        className="form-control-l form-control"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl="4" sm="6" xs="12" className="mb-2">
                            <div className="form-control-wrap">
                                <Input
                                    errors={errors}
                                    required
                                    requiredMessage={{
                                        required: "This field is required",
                                        pattern: {
                                            value: /^.{1,40}$/,
                                            message: "Max 40 characters",
                                        },
                                    }}
                                    register={register}
                                    type="text"
                                    label="Client Last Name"
                                    id="client_name_last"
                                    name="client_name_last"
                                    className="form-control-l form-control"
                                />
                            </div>
                        </Col>
                        <Col xl="4" sm="6" xs="12" className="mb-2">
                            <div className="form-control-wrap">
                                <Input
                                    errors={errors}
                                    register={register}
                                    type="text"
                                    label="Client ID"
                                    id="client_id"
                                    name="client_id"
                                    className="form-control-l form-control"
                                    requiredMessage={{
                                        pattern: {
                                            value: /^.{1,80}$/,
                                            message: "Max 80 characters",
                                        },
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="4" sm="6" xs="12" className="mb-2">
                            <div className="form-control-wrap">
                                <Input
                                    errors={errors}
                                    required
                                    requiredMessage={{
                                        required: "This field is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: "Please enter valid email address",
                                        },
                                    }}
                                    register={register}
                                    type="text"
                                    label="Client Email"
                                    id="client_email"
                                    name="client_email"
                                    className="form-control-l form-control"
                                />
                            </div>
                        </Col>
                        <Col xl="4" sm="6" xs="12" className="mb-2">
                            <div className="form-control-wrap">
                                <Input
                                    errors={errors}
                                    required
                                    requiredMessage={{
                                        required: "This field is required",
                                        pattern: {
                                            value: /^\+?1?[2-9][0-8][0-9]\d{3}\d{4}$/,
                                            message: "Please input valid phone number",
                                        },
                                    }}
                                    register={register}
                                    type="text"
                                    label="Client Phone Number"
                                    id="client_phone"
                                    name="client_phone"
                                    className="form-control-l form-control"
                                />
                            </div>
                        </Col>
                        <Col xl="4" sm="6" xs="12" className="mb-2">
                            <Row>
                                <RSelect2
                                    onselect={onselect}
                                    setValue={setValue}
                                    // selectedCountry={watch("region")}
                                    label="Matter"
                                    register={register}
                                    requiredMessage="This field is required"
                                    id="matter"
                                    name="matter"
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    createable={true}
                                    options={props.matterList}
                                    placeholder="Select or type new matter"
                                    className="form-control-lg p-0 border-0 form-control"
                                />
                            </Row>
                        </Col>
                    </Row>
                    {isNewMatter && <Row>
                        <Col xl="12" sm="6" xs="12" className="mb-2">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Matter Description or Reason<span className="text-danger">*</span>
                                </label>
                            </div>
                            <div className="form-control-wrap">
                                <textarea
                                    id="request_reason"
                                    name="request_reason"
                                    type="text"
                                    className="  form-control form-control-lg "
                                    {...register("request_reason", {
                                        required: "This field is required",
                                        pattern: {
                                            value: /^.{1,128}$/,
                                            message: "Max 128 characters",
                                        },
                                    })}
                                ></textarea>
                                {errors.request_reason && <p className="invalid">{errors.request_reason.message}</p>}
                            </div>
                        </Col>
                    </Row>}



                    <div className="card-inner-group">
                        <div className="mt-2 text-muted small">
                            Please make sure the request details are correct and accurate, you cannot change them once sent, and
                            there are no refunds.
                        </div>
                    </div>
                </Row >
            </Container >
            <div className="actions clearfix">
                <ul>
                    <li>
                        <Button color="primary" type='submit' >Next</Button>
                    </li>
                    <li>
                        <Button color="primary" onClick={props.props.prev} >Back</Button>
                    </li>
                </ul>
            </div>
            <ToastContainer />
        </div>


    );
}

export default KycWizardUserNew