import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import { BlockAthenty, Block, Icon, Input, Loading } from "../../../../../components/Component";
import RSelect2 from "../../../../../components/select/ReactSelect2";
import classnames from "classnames";
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Card } from "reactstrap";
import { apiRequest } from "../../../../../utils/Api";
import { useNavigate } from "react-router";

const EditUser = ({ mode }) => {
  const [activeIconTab, setActiveIconTab] = useState("1");
  const [formData, setFormData] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [options, setOptions] = useState()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const submitRef = useRef();

  const userData = {
    first_name: "Joe ",
    last_name: "Smith",
    email: "joe@email.com",
    phoneNumber: "416-123-4567",
    title: "Associate",
    role: "Corporate Lawyer",
    licence: "Free",
    permission: "Default",
    lastLogin: "12-12-1212",
  };

  useEffect(() => {
    mode !== 'edit' && getPackageShort()
  }, [])

  const getPackageShort = async () => {
    setLoading(true)
    try {
      await apiRequest("/customer/a/package/list-short", "GET").then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          let option = []
          resp.data.forEach(pkg => {
            option.push({
              value: pkg.id,
              label: `${pkg.name} (${pkg.remaining} remaning)`
            })
          })
          setOptions(option)
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const onselect = (name, value) => {
    if (name === "package") {
      setValue("package", value);
    }
    if (getValues(name)) {
      delete errors[name];
      console.log(errors)
    }
  };

  const asignRef = () => {

    return submitRef.current.click();
  };

  const onFormSubmit = async (formData) => {
    setButtonLoading(true)
    await apiRequest("/customer/a/agents/add", "POST", getValues()).then((resp) => {
      console.log(resp);

      if (resp.code === 200) {
        console.log("success");
        navigate("/customer/admin/access/users", { state: "userAdded" });
        toast.success("New Agent Added Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (resp.code === 401) {
        toast.error("Access Denied", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (resp.code === 403) {
        toast.error("Email Already Exist", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (resp.code === '500') {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
    setButtonLoading(false)
  };

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  return (

    <React.Fragment>
      {loading && <Loading />}
      {!loading && options && (<>
        <BlockAthenty
          head={mode === 'edit' ? "Edit User" : 'Add new user'}
          nav="Admin / Access / Users"
          title={mode === "edit" ? "Edit" : "Add"}
          description={mode === "edit" ? "Edit user's profile" : "Add new user"}
          loading={buttonLoading}
          buttons={[
            {
              text: "Save",
              icon: "save",
              onclick: asignRef,
              tooltip: "Save Changes",
              id: "save-button",
              type: "submit",
              form: "my-form",
              disabled: buttonLoading
            },
          ]}
          back={"/customer/admin/access/users"}
        />
        <Block size="lg">
          <Card className="card-bordered">
            <Nav tabs>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "1" })}
                  style={{ marginLeft: "24px" }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <Icon name="user-alt" /> <span>Personal</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeIconTab}>
              <TabPane tabId="1">
                <form
                  className="is-alter"
                  style={{ paddingLeft: "24px", paddingRight: "24px", paddingBottom: "24px" }}
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <div className="form-group">
                    <div className="form-control-wrap">
                      {mode !== 'edit' && <div className="row">
                        <Col>
                          <label htmlFor="package" className="form-label">
                            Package <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col>
                          <RSelect2
                            id="package"
                            name="package"
                            register={register}
                            options={options}
                            requiredMessage="This field is required"
                            errors={errors}
                            className="p-0 border-0 form-control"
                            onselect={onselect}
                            setValue={setValue}
                            placeholder="Select Package"
                          />
                        </Col>
                      </div>}
                      <div className="row">
                        <div className="col">
                          <label htmlFor="name_first" className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="name_first"
                            name="name_first"
                            type="text"
                            register={register}
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,40}$/,
                                message: "Max 40 characters",
                              },
                            }}
                            errors={errors}
                            className=" form-control"
                            defaultValue={mode === "edit" ? userData.name_first : ""}
                          />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col">
                          <label htmlFor="name_last" className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="name_last"
                            name="name_last"
                            type="text"
                            register={register}
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,40}$/,
                                message: "Max 40 characters",
                              },
                            }}
                            errors={errors}
                            className=" form-control"
                            defaultValue={mode === "edit" ? userData.name_last : ""}
                          />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col">
                          <label htmlFor="title" className="form-label">
                            Title or Position<span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            register={register}
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,80}$/,
                                message: "Max 80 characters",
                              },
                            }}
                            errors={errors}
                            className=" form-control"
                            defaultValue={mode === "edit" ? userData.title : ""}
                          />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col">
                          <label htmlFor="email" className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="email"
                            name="email"
                            type="text"
                            register={register}
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Please enter valid email address",
                              },
                            }}
                            errors={errors}
                            className=" form-control"
                            defaultValue={mode === "edit" ? userData.email : ""}
                          />
                        </div>
                      </div>

                      {/* <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="phone-number" className="form-label">
                          Work Number <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="phone_work"
                          name="phone_work"
                          type="text"
                          register={register}
                          required
                          requiredMessage="This field is required"
                          errors={errors}
                          className=" form-control"
                          defaultValue={mode === "edit" ? userData.phone_work : ""}
                        />
                      </div>
                    </div> */}

                      <div className="row mt-2">
                        <div className="col">
                          <label htmlFor="phone-number" className="form-label">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="phone_mobile"
                            name="phone_mobile"
                            type="text"
                            register={register}
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^\+?1?[2-9][0-8][0-9]\d{3}\d{4}$/,
                                message: "Please input valid phone number",
                              },
                            }}
                            errors={errors}
                            className=" form-control"
                            defaultValue={mode === "edit" ? userData.phone_mobile : ""}
                          />
                        </div>
                      </div>

                      {/* <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="role" className="form-label">
                          Role or Profession<span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="role"
                          name="role"
                          type="text"
                          register={register}
                          required
                          requiredMessage="This field is required"
                          errors={errors}
                          className=" form-control"
                          defaultValue={mode === "edit" ? userData.role : ""}
                        />
                      </div>
                    </div> */}

                      {/* <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="licence" className="form-label">
                          Licence <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <RSelect2
                          options={licenceOptions}
                          name="licence"
                          register={register}
                          // requiredMessage="This field is required"
                          errors={errors}
                          defaultValue={mode === "edit" ? { value: userData.licence, label: userData.licence } : ""}
                          setValue={setValue}
                          onselect={onselect}
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </div>
                    </div> */}

                      {/* <div className="row">
                      <div className="col">
                        <label htmlFor="permission" className="form-label">
                          Permission <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <RSelect2
                          options={permissionOptions}
                          name="permission"
                          register={register}
                          // requiredMessage="This field is required"
                          errors={errors}
                          // defaultValue={mode==='edit' ? {value: userData.permission, label: userData.permission} : '' }
                          // defaultValue={{ value: "Free", label: "Free" }}
                          setValue={setValue}
                          onselect={onselect}
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </div>
                    </div> */}

                      <button ref={submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
                    </div>
                  </div>
                </form>
              </TabPane>
            </TabContent>
          </Card>
          <ToastContainer />
        </Block>
      </>)}
    </React.Fragment>
  );
};

export default EditUser;
