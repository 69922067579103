import React, { useContext, useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { PreviewCard, ClientListTable } from "../../../../../../components/Component";

import { Link, useLocation } from "react-router-dom";
import Head from "../../../../../../layout/head/Head";
import moment from "moment";
import { UserContext } from "../../../../../../layout/Index";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../../../components/Component";
import { clientVerificationListColumns } from "../../../../../components/table/TableData";
import MainTable from "../../../../../components/athenty/table/Main.Table";



const ClientVerification = (props) => {
  const [vetificationsData, setVerificationsData] = useState(props.data);
  const location = useLocation()
  const { userData } = useContext(UserContext)


  console.log(location)
  console.log(vetificationsData)
  const col = clientVerificationListColumns.filter(function (col) {
    if (col.access) {
      if (col.access.includes(userData.class)) return col
      else return null
    } else {
      return col
    }

  })
  return (
    <React.Fragment>
      <Content>
        <Head title="Client Details"></Head>
        <BlockHead size="md">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Verifications</BlockTitle>
              <BlockDes>
                <p>Recent Smart IDVs and KYCs requests</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {/* <PreviewCard> */}
        <MainTable compact columns={col} route={`/customer/a/client/${props.client_id}/requests`} />
        {/* </PreviewCard> */}

      </Content>
    </React.Fragment>
  );
};

export default ClientVerification;
